import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/SideBar';
import NavBar from '../../components/NavBarSearchSong';
import Settings from '../../assets/Settings.png';
import RoundButton from '../../components/RoundButton';
// import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PlayBtn from '../../assets/play_btn.svg';
import Delete from '../../assets/Delete.png';
import search from '../../assets/ic_search.png';
import './EditPlaylistSecondery.scss';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { useLocation } from 'react-router-dom';
import { _showAlert, formatDateToDisplay, _isAuthenticated } from '../../utils';
import { LoaderSpinner } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import AlertModal from '../../components/Modal';
import UploadImageSvg from './UpalodImageSvg';
import { useRef } from 'react';
import ApiCaller from '../../redux/ApiCaller';
import { CircularProgress, Grid } from '@material-ui/core';
import Dropdown from '../../components/Dropdown';
import { royalty } from '../../generalConstant';
import Popup from '../../components/Popup';
import SongPlayer from '../../components/SongPlayer';
import plusIcon from '../../assets/icons8-plus.svg';

/* 
Table Data and Use Style
*/
const useStyles = makeStyles({
  table: {
    minWidth: 700,
    borderRadius: 30,
  },
});

const SortableItem = SortableElement(
  ({ row, handleRemoveSong, isSongLoading, currentPlayingSong, setIsSongDialogOpen, isSongDialogOpen, indexToDisplay }) => {
    const isLoading = isSongLoading && currentPlayingSong.id === row.id;

    return (
      <>
        <TableRow>
          {/* <TableCell>
        <Checkbox inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} />
      </TableCell> */}
          <TableCell className="table-index-cell">{`#${indexToDisplay}`}</TableCell>
          <TableCell>{formatDateToDisplay(row.createdAt)}</TableCell>
          <TableCell>{row.name || row.fileName || 'N/A'}</TableCell>
          <TableCell>
            <div style={{ height: '100%', width: '60px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <div
                    style={{
                      position: 'relative',
                    }}
                  >
                    <Grid item className="tooltip-wrapper">
                      {isSongDialogOpen.isOpen && isSongDialogOpen.id === row?.id ? (
                        <SongPlayer
                          songDetail={row}
                          onClose={() => {
                            setIsSongDialogOpen({
                              id: 0,
                              isOpen: false,
                            });
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </Grid>

                    <img
                      src={PlayBtn}
                      alt=""
                      onClick={() =>
                        setIsSongDialogOpen({
                          id: row.id,
                          isOpen: true,
                        })
                      }
                    />
                  </div>
                  <img
                    alt=""
                    style={{ height: '21px', width: '21px', objectFit: 'contain' }}
                    src={Delete}
                    onClick={() => handleRemoveSong(row)}
                  />
                </>
              )}
            </div>
          </TableCell>
        </TableRow>
      </>
    );
  }
);

const SortableList = SortableContainer(
  ({
    items,
    isChangeOrder,
    handleRemoveSong,
    onPlaySong,
    isSongLoading,
    isSongPlaying,
    currentPlayingSong,
    setIsSongDialogOpen,
    isSongDialogOpen,
  }) => {
    return (
      <TableBody>
        {items.map((row, index) => (
          <SortableItem
            onPlaySong={onPlaySong}
            handleRemoveSong={handleRemoveSong}
            disabled={isChangeOrder}
            key={`item-${index}`}
            index={index}
            indexToDisplay={index + 1}
            row={row}
            isSongLoading={isSongLoading}
            isSongPlaying={isSongPlaying}
            currentPlayingSong={currentPlayingSong}
            setIsSongDialogOpen={setIsSongDialogOpen}
            isSongDialogOpen={isSongDialogOpen}
          />
        ))}
      </TableBody>
    );
  }
);

function EditPlaylistSecondery(props) {
  const classes = useStyles();
  const [selectedPlaylist, setPlaylist] = useState({});
  const [playlistSongs, setPlaylistSongs] = useState([]);
  const [isChangeOrder, setChangeOrder] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const routeLocation = useLocation();
  const [isFocusOnName, setFocusOnName] = useState(false);
  const [name, setName] = useState('');
  const updatePlaylist = useSelector((state) => state.updatePlaylist.result);
  const songURL = useSelector((state) => state.getSongURL.result);
  const [open, setOpen] = React.useState(false);
  const [selectedSong, setSelectedSong] = useState(null);
  //
  const tagList = useSelector((state) => state.tagList.result);

  const [isAddTagPopUpOpen, setIsAddTagPopUpOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);

  const [removeApiTagsIds, setRemoveApiTagsIds] = useState([]);

  const [isEditPopUpOpen, setIsEditPopUpOpen] = useState(false);
  const [isDraggableView, setIsDraggableView] = useState(false);
  const [imageUploadedFromPc, setImageUpaloadedFromPc] = useState('');
  const [image, setImage] = useState(null);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [isSongLoading, setIsSongLoading] = useState(false);
  const [isSongPlaying, setIsSongPlaying] = useState(false);

  const [currentPlayingSong, setCurrentPlayingSong] = useState(null);

  const imageInputRef = useRef(null);
  const audioRef = useRef(null);

  const dispatch = useDispatch();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setPlaylistSongs(arrayMove(selectedPlaylist.songs, oldIndex, newIndex));
  };

  const urlData = props.location.pathname.split('/');
  const isRoyalties = urlData.includes('royalties');

  const [isRoyalty, setIsRoyalty] = useState(isRoyalties ? royalty[0] : royalty[1]);
  let isRoyaltyValue = isRoyalty.value === 'true';

  const [isSongDialogOpen, setIsSongDialogOpen] = useState({
    id: 0,
    isOpen: false,
  });

  useEffect(() => {
    if (routeLocation?.state?.selectedPlayList) {
      setPlaylist(routeLocation.state.selectedPlayList);
      setPlaylistSongs(routeLocation.state.selectedPlayList.songs);
      setName(routeLocation.state.selectedPlayList.name);
    }
  }, [routeLocation]);

  useEffect(() => {
    if (updatePlaylist.id) {
      setLoading(false);
      _showAlert('Playlist Successfully Updated', 'success');
      props.history.push(isRoyalties ? '/royalties/edit-playlist-main' : '/non-royalties/edit-playlist-main');
    }
    if (updatePlaylist.error) {
      setLoading(false);
      _showAlert(updatePlaylist.error, 'error');
    }
  }, [updatePlaylist]);

  //* Fetch Tags from API
  useEffect(() => {
    dispatch({ type: 'TAG_LIST' });
  }, []);

  useEffect(() => {
    return () => {
      dispatch({ type: 'CLEAR_STATE', actionName: 'UPDATE_PLAYLIST' });
    };
  }, []);

  useEffect(() => {
    if (songURL.status === 200 && songURL.url) {
      window.open(songURL.url, '_blank');
      dispatch({ type: 'CLEAR_STATE', actionName: 'GET_SONG_URL' });
    }
    if (songURL?.error) {
      _showAlert(songURL?.error, 'error');
    }
  }, [songURL]);

  // const handlePlaySong = async (song, index) => {
  //   if (!audioRef.current) {
  //     // show error;
  //     return;
  //   }
  //   const prevSong = currentPlayingSong;
  //   setCurrentPlayingSong(song);

  //   if (prevSong?.id === song.id) {
  //     if (isSongPlaying) {
  //       audioRef.current.pause();
  //       setIsSongPlaying(false);
  //       return;
  //     }
  //     setIsSongLoading(true);
  //     audioRef.current.src = `${process.env.REACT_APP_API_URL}/songs/${song.id}.mp3`;
  //     audioRef.current.play().then(() => {
  //       setIsSongLoading(false);
  //       setIsSongPlaying(true);
  //     });
  //     return;
  //   }

  //   if (isSongPlaying) {
  //     audioRef.current.pause();
  //     setIsSongPlaying(false);
  //   }

  //   setIsSongLoading(true);
  //   audioRef.current.src = `${process.env.REACT_APP_API_URL}/songs/${song.id}.mp3`;
  //   audioRef.current.play().then(() => {
  //     setIsSongLoading(false);
  //     setIsSongPlaying(true);
  //   });
  // };

  const handleAddNewSongs = () => {
    props.history.push({
      pathname: isRoyalties ? '/royalties/edit-playlist-and-server' : '/non-royalties/edit-playlist-and-server',
      state: { selectedPlaylist },
    });
  };

  const handleUpdatePlaylist = () => {
    setLoading(true);
    let songs = playlistSongs.map((item, index) => ({ id: item.id, index }));
    dispatch({
      type: 'UPDATE_PLAYLIST',
      payload: {
        id: selectedPlaylist.id,
        name: name,
        songs,
        type: isRoyaltyValue ? 3 : 4,
      },
    });

    if (selectedTags.length) {
      const tagIds = selectedTags.map((tag) => tag.id) || [];
      dispatch({ type: 'ADD_PLAYLIST_TAG', payload: { playlistId: selectedPlaylist.id, tagIds } });
      setSelectedTags([]);
      setSearchInput('');
    }

    if (removeApiTagsIds.length) {
      dispatch({ type: 'DELETE_PLAYLIST_TAG', payload: { playlistId: selectedPlaylist.id, tagIds: removeApiTagsIds } });
      setRemoveApiTagsIds([]);
    }
  };

  const handleTagSelection = (tag) => {
    setSelectedTags((prevSelectedTags) =>
      prevSelectedTags.includes(tag) ? prevSelectedTags.filter((t) => t !== tag) : [...prevSelectedTags, tag]
    );
  };

  //* Tags excluding already added tags
  const filteredTags = tagList.tags?.filter(
    (tag) =>
      tag.name.toLowerCase().includes(searchInput.toLowerCase()) &&
      !selectedPlaylist.tags?.some((selectedTag) => selectedTag.name === tag.name)
  );

  const combinedTags = [...(selectedPlaylist.tags || []), ...(selectedTags || [])];

  const handleSortByDate = () => {
    let sortedItems = playlistSongs.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(a.createdAt) - new Date(b.createdAt);
    });
    setPlaylistSongs([...sortedItems]);
  };

  const handleShuffle = () => {
    var currentIndex = playlistSongs.length,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [playlistSongs[currentIndex], playlistSongs[randomIndex]] = [playlistSongs[randomIndex], playlistSongs[currentIndex]];
    }
    setPlaylistSongs([...playlistSongs]);
  };

  const handleRemoveSong = (song) => {
    setOpen(false);
    setPlaylistSongs(playlistSongs.filter((item) => item.id !== song.id));
  };

  const handleDeleteConfirmModal = (song) => {
    setOpen(true);
    setSelectedSong(song);
  };

  const handleUpdateImageClick = () => {
    setIsEditPopUpOpen(true);
    if (!selectedPlaylist.image) {
      // write logic here to fetch image
      setIsDraggableView(true);
    }
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
    } else if (e.type === 'dragleave') {
    }
  };

  const convertImageToUrl = (file) => {
    setImage(file);
    setImageUpaloadedFromPc(URL.createObjectURL(file));
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const type = e?.dataTransfer?.files?.[0]?.type;
      if (type === 'image/jpg' || type === 'image/png' || type === 'image/jpeg') {
        // handleFiles(e.dataTransfer.files);
        convertImageToUrl(e.dataTransfer.files[0]);
        setIsDraggableView(false);
      }
    }
  };

  const uploadImage = async () => {
    if (!image) {
      _showAlert('Please select image to upload', 'error');
      return;
    }
    try {
      setIsImageUploading(true);
      const formData = new FormData();
      formData.append('file', image);
      const playListId = props?.location?.state?.selectedPlayList?.id;
      const { tokens } = _isAuthenticated();
      const headers = {
        authorization: `${tokens?.accessToken}`,
      };
      await ApiCaller(`/v1/admin/playlists/${playListId}/image`, 'POST', formData, headers);
      setIsImageUploading(false);
      setIsEditPopUpOpen(false);
      _showAlert('Platlist image updated successfully', 'success');
    } catch (error) {
      _showAlert(error.message, 'error');
      setIsImageUploading(false);
      setIsEditPopUpOpen(false);
    }
  };

  return (
    <>
      <audio ref={audioRef} crossOrigin="anonymous" />
      {isEditPopUpOpen && (
        <div className="backDrop" onClick={() => setIsEditPopUpOpen(false)}>
          <div className="modal" onClick={(e) => e.stopPropagation()}>
            <p className="headerText">Upload Image</p>
            <div className="contentContainer">
              {isDraggableView ? (
                <>
                  <form
                    id="form-file-upload"
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}
                    onDragEnter={handleDrag}
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <div
                      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                      onDragEnter={handleDrag}
                      onDragLeave={handleDrag}
                      onDragOver={handleDrag}
                      onDrop={handleDrop}
                    >
                      <UploadImageSvg />
                      <p className="dragContent">Drag & Drop or Browse the files from your computer (only Jpeg and PNG)</p>
                    </div>
                    <RoundButton btnText="Select" className="modal_btn" handleClick={() => imageInputRef.current.click()} />
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      ref={imageInputRef}
                      onChange={(e) => {
                        convertImageToUrl(e.target.files[0]);
                        setIsDraggableView(false);
                      }}
                      accept="image/jpg, image/png, image/jpeg"
                    />
                  </form>
                </>
              ) : (
                <div style={{ flex: 1, width: '100%', display: 'flex', flexDirection: 'column' }}>
                  <div className="imageContainer">
                    <img style={{ height: '120px', width: '120px', objectFit: 'contain' }} src={imageUploadedFromPc} alt="alt" />
                  </div>
                  <div className="btnContainer">
                    {isImageUploading ? (
                      <CircularProgress />
                    ) : (
                      <>
                        <RoundButton className="modal_btn white_btn" btnText="Change" handleClick={() => setIsDraggableView(true)} />
                        <RoundButton className="modal_btn" btnText="Confirm" handleClick={uploadImage} />
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="main edit-playlist-secondery">
        {isLoading && <LoaderSpinner />}
        <div className="sidebar">
          <Sidebar />
        </div>
        <AlertModal open={open} onClose={() => setOpen(false)} handelDelete={() => handleRemoveSong(selectedSong)} />
        <div className="leftPortion">
          <NavBar
            data="Edit Playlist"
            icon={Settings}
            currentRouteName="Playlist"
            breadcrumbsPath="Edit Playlist"
            text={isRoyalties ? 'Royalty' : 'Non-Royalty'}
          />
          <div className="content">
            <div className="tblUpperRow">
              <input
                className={!isFocusOnName && 'readonly-input'}
                onFocus={() => setFocusOnName(true)}
                readOnly={!isFocusOnName}
                value={name}
                name="name"
                onChange={(event) => setName(event.target.value)}
                onBlur={() => setFocusOnName(false)}
              />
              <div className="grp_btn">
                <Dropdown options={royalty} value={isRoyalty} setValue={setIsRoyalty} style={{ minWidth: '120px' }} />
                <RoundButton btnText="Update Image" handleClick={handleUpdateImageClick} />
                <RoundButton btnText="Sort by date" handleClick={handleSortByDate} />
                <RoundButton
                  className={!isChangeOrder && 'disabled-button'}
                  btnText="Change order"
                  handleClick={() => setChangeOrder(!isChangeOrder)}
                />
                <RoundButton btnText="Shuffle" handleClick={handleShuffle} />
                <RoundButton btnText="Add Songs" handleClick={handleAddNewSongs} />
              </div>
            </div>

            <div className="tag_container">
              {combinedTags.map((tag, index) => (
                <div key={index} className="tag_item">
                  <div
                    className="color_circle tag_circle"
                    style={{
                      backgroundColor: tag.color || '#e7ddff',
                    }}
                  />
                  <label className="tag_name">{tag.name}</label>
                  <div
                    className="cross-icon"
                    onClick={() => {
                      if (selectedTags.includes(tag)) {
                        setSelectedTags((prevSelectedTags) => prevSelectedTags.filter((t) => t !== tag));
                      }
                      if (selectedPlaylist && selectedPlaylist.tags) {
                        const filteredTags = selectedPlaylist.tags.filter((t) => t.name !== tag.name);
                        setPlaylist({ ...selectedPlaylist, tags: filteredTags });
                        if (tag.tagId) {
                          setRemoveApiTagsIds((prev) => [...prev, tag.tagId]);
                        }
                      }
                    }}
                  />
                </div>
              ))}
              <div
                className="add_tag"
                onClick={() => {
                  setIsAddTagPopUpOpen(true);
                }}
              >
                <p className="tag_text">Add Tag</p>
                <img src={plusIcon} alt="plus icon" />
              </div>
            </div>

            <TableContainer className="tbl_wraper table-height" component={Paper}>
              <Table className={classes.table} stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {/* <TableCell>
                    <Checkbox inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} />
                  </TableCell> */}
                    <TableCell className="table_font table-index-cell">Result</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Song Name</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                {Array.isArray(playlistSongs) && playlistSongs.length ? (
                  <SortableList
                    handleRemoveSong={handleDeleteConfirmModal}
                    isChangeOrder={isChangeOrder}
                    items={playlistSongs}
                    onSortEnd={onSortEnd}
                    pressDelay={200}
                    // onPlaySong={handlePlaySong}
                    isSongLoading={isSongLoading}
                    isSongPlaying={isSongPlaying}
                    currentPlayingSong={currentPlayingSong}
                    setIsSongDialogOpen={setIsSongDialogOpen}
                    isSongDialogOpen={isSongDialogOpen}
                  />
                ) : null}
              </Table>
            </TableContainer>
            <div className="save_row">
              <RoundButton btnText="Save" handleClick={handleUpdatePlaylist} />
            </div>
          </div>
        </div>
        {isAddTagPopUpOpen && (
          <Popup open={isAddTagPopUpOpen} onClose={() => setIsAddTagPopUpOpen(false)}>
            <div
              className="create_playlist tag_popup"
              style={{
                width: '500px',
              }}
            >
              <p className="header">Tags</p>
              <div className="tag_search_row">
                <input
                  className="input_field"
                  placeholder="Search tags here..."
                  type="text"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <img src={search} alt="search icon" />
              </div>

              <div
                className="tag_list"
                style={{
                  height: '180px',
                  overflowY: 'auto',
                }}
              >
                {filteredTags.map((tag) => (
                  <div key={tag.id} className="tag_item">
                    <input type="checkbox" checked={selectedTags.includes(tag)} onChange={() => handleTagSelection(tag)} />
                    <label>
                      {
                        // tag?.name upto 20 characters + '...'
                        tag?.name.length > 20 ? tag?.name.substring(0, 20) + '...' : tag?.name
                      }
                    </label>
                  </div>
                ))}
              </div>

              <RoundButton className="add-btn" btnText={'Add'} handleClick={() => setIsAddTagPopUpOpen(false)} />
            </div>
          </Popup>
        )}
      </div>
    </>
  );
}

export default EditPlaylistSecondery;

