
export default function reducer(
  state = {
    status: 'uninitiated',
    result: {},
  },
  action
) {
  switch (action.type) {
    case 'TAG_LIST_INITIATED': {
      return { ...state, status: 'initiated' };
    }
    case 'TAG_LIST_SUCCESS': {
      return { ...state, status: 'success', result: action.payload };
    }
    case 'TAG_LIST_FAILED': {
      return { ...state, status: 'failed', result: action.payload };
    }
    default: {
      return state;
    }
  }
}
