import moment from 'moment';

export const formatDateToDisplay = (date) => {
  return date ? moment(date).format('MMM DD, YYYY, hh:mm A') : '22-07-2021';
};

export const formatDuration = (durationVal) => {
  const duration = moment.duration(durationVal, 'seconds');
  const formattedDuration = moment.utc(duration.asMilliseconds()).format('mm:ss');
  return formattedDuration;
};

export const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

