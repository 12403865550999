import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { makeStyles } from '@material-ui/core/styles';

import RoundButton from '../../components/RoundButton';
import NavBar from '../../components/NavBarSearchSong';
import { LoaderSpinner } from '../../components';
import Sidebar from '../../components/SideBar';
import Popup from '../../components/Popup';
import EditIcn from '../../assets/edit_icn.svg';
import Delete from '../../assets/Delete.png';

import { _showAlert } from '../../utils';

import '../CustomerList/CustomerList.scss';
import '../TagList/TagList.scss';

//* material ui table style

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    borderRadius: 30,
  },
});

function TagList() {

  const { tagList, addTag, editTag, deleteTag } = useSelector((state) => ({
    tagList: state.tagList.result,
    addTag: state.addTag.result,
    editTag: state.editTag.result,
    deleteTag: state.deleteTag.result,
  }));

  console.log(editTag, "editTag")

  const dispatch = useDispatch();
  const classes = useStyles();

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [tagName, setTagName] = useState('');
  const [color, setColor] = useState('#e7ddff');
  const [isLoading, setIsLoading] = useState(false);
  const [tagId, setTagId] = useState(null);

  const handleEditTag = (tag) => {
    setIsPopupOpen(true);
    setTagId(tag.id);
    setTagName(tag.name);
    setColor(tag.color);
  };

  //* Add Tag
  const handleCreateTag = (id) => {
    const data = {
      name: tagName,
      color,
    };
    setIsLoading(true);
    if (id) {
      dispatch({ type: 'UPDATE_TAG', payload: { tagId: id, tag: data } });
    } else {
      dispatch({ type: 'NEW_TAG', payload: data });
    }

    setIsLoading(false);
    setIsPopupOpen(false);
    setTagId(null);
    setTagName('');
    setColor('#e7ddff');
  };

  useEffect(() => {
    if (addTag && addTag.id) {
      _showAlert('Tag has been created successfully.', 'success');
      dispatch({ type: 'TAG_LIST' });
    }
    if (addTag.error) {
      setIsLoading(false);
      _showAlert(addTag.error, 'error', 10000);
    }
  }, [addTag]);

  useEffect(() => {
    if (deleteTag.status) {
      _showAlert('Tag has been deleted successfully.', 'success');
      dispatch({ type: 'TAG_LIST' });
    }
    if (deleteTag?.error) {
      _showAlert(deleteTag?.error, 'error', 10000);
    }
  }, [deleteTag]);

  useEffect(() => {
    if (editTag.status) {
      _showAlert('Tag has been updated successfully.', 'success');
      dispatch({ type: 'TAG_LIST' });
    }
    if (editTag?.error) {
      _showAlert(editTag?.error, 'error', 10000);
    }
  }, [editTag]);

  useEffect(() => {
    dispatch({ type: 'TAG_LIST' });
  }, []);

  useEffect(() => {
    return () => {
      dispatch({ type: 'CLEAR_STATE', actionName: 'ADD_TAG' });
      dispatch({ type: 'CLEAR_STATE', actionName: 'UPDATE_TAG' });
      dispatch({ type: 'CLEAR_STATE', actionName: 'DELETE_TAG' });
    };
  }, []);

  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="leftPortion">
          <NavBar data="All Tags" currentRouteName="Tags" breadcrumbsPath="Tags List" />
          <div className="content">
            <div className="search_row">
              <RoundButton handleClick={() => setIsPopupOpen(true)} btnText="Add Tag" />
            </div>
            <TableContainer className="tbl_wraper customer-list-main" component={Paper}>
              <Table className={classes.table} stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell className="table_font">Result</TableCell>
                    <TableCell>Tag Name</TableCell>
                    <TableCell>Color</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(tagList.tags) &&
                    tagList.tags.map((tag, index) => (
                      <TableRow key={tag.id}>
                        <TableCell style={{ fontWeight: 'bold' }}>#{++index}</TableCell>
                        <TableCell>{tag.name || 'N/A'}</TableCell>
                        <TableCell>
                          <div
                            className="color_circle"
                            style={{
                              backgroundColor: tag.color || '#e7ddff',
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <div className="action_icons">
                            <img onClick={() => handleEditTag(tag)} alt="edit" src={EditIcn} />
                            <img
                              onClick={() => {
                                dispatch({ type: 'DELETE_TAG', payload: { tagId: tag.id } });
                              }}
                              alt="delete"
                              src={Delete}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      {
        <Popup
          open={isPopupOpen}
          onClose={() => {
            setIsPopupOpen(false);
            setTagName('');
            setColor('#e7ddff');
          }}
        >
          <div className="create_taglist">
            <p className="header">{tagId ? 'Edit Tag' : 'Create Tag'}</p>
            <div className="tag_container">
              <input
                className="tag_input"
                value={tagName}
                placeholder="Tag Name"
                type="text"
                onChange={(e) => setTagName(e.target.value)}
              />
              <input type="color" value={color} className="color_input" onChange={(e) => setColor(e.target.value)} />
            </div>
            <RoundButton className="add-btn" btnText={tagId ? 'Update Tag' : 'Create'} handleClick={() => handleCreateTag(tagId)} />
            {isLoading && <LoaderSpinner />}
          </div>
        </Popup>
      }
    </>
  );
}

export default TagList;

