export const sidebarData = {
  manuList: [
    {
      manuName: 'Songs',
      subMenu: [
        { name: 'Add Songs', iconName: 'add-songs', route: '/add-songs', allPaths: ['/add-songs'] },
        { name: 'Search Songs', iconName: 'search-songs', route: '/search-songs', allPaths: ['/search-songs'] },
      ],
    },
    {
      manuName: 'Playlists',
      subMenu: [
        // { name: 'New Playlist', iconName: 'new-playlist', route: '/new-playlist', allPaths: ['/new-playlist'] },
        {
          name: 'Edit Playlists',
          iconName: 'edit-playlist',
          route: '/royalties/edit-playlist-main',
          allPaths: ['/royalties/edit-playlist-main', '/non-royalties/edit-playlist-main',
            '/royalties/edit-playlist-secondery', '/royalties/edit-playlist-and-server',
            '/non-royalties/edit-playlist-secondery', '/non-royalties/edit-playlist-and-server'],
        },
      ],
    },
    {
      manuName: 'Customers',
      subMenu: [
        {
          name: 'All Customers',
          iconName: 'all-customers',
          route: '/customer-list',
          allPaths: ['/customer-list', '/customer-details', '/edit-customer-playlists', '/edit-customer-scheduler', '/update-customer'],
        },
        { name: 'New Customer', iconName: 'new-customer', route: '/new-customer', allPaths: ['/new-customer'] },
      ],
    },
    {
      manuName: 'Tags',
      subMenu: [
        {
          name: 'All Tags',
          iconName: 'all-tags',
          route: '/tags',
          allPaths: ['/tags'],
        },
      ],
    },
  ],
};
