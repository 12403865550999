import { put, takeLatest, call } from 'redux-saga/effects';
import ApiCaller from '../ApiCaller';

const callAPI = (payload) => {
  return ApiCaller(`/v1/admin/tag/${payload.tagId}`, 'PATCH', payload.tag);
};

export default function* watchRequest() {
  yield takeLatest('UPDATE_TAG', function* performAction(action) {
    yield put({ type: 'UPDATE_TAG_INITIATED', });

    try {
      const data = yield call(callAPI.bind(this, action.payload));

      let response = data.response;
      if (response) response = yield data.response.json();

      if (!data.status && Object.keys(data).length > 0) {
        yield put({
          type: 'UPDATE_TAG_FAILED', payload: {
            status: false,
            error: response?.message || data.error,
          }
        });
      }
      else {
        yield put({
          type: 'UPDATE_TAG_SUCCESS', payload: {
            status: true,
          }
        });
      }
    } catch (error) {
      yield put({
        type: 'UPDATE_TAG_FAILED', payload: {
          status: false,
          error,
        }
      });
    }
  });
}

