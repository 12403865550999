/**
 * Name: ROUTES DATA
 * Author: @Gaurav
 * Date: 15-07-2021
 */
import Login from '../pages/Login';
import CustomerList from '../pages/CustomerList';
import SearchSongs from '../pages/Songs';
import AddSongs from '../pages/AddSong';
import EditPlaylistSecondery from '../pages/EditPlaylistSecondery';
import EditPlaylistAndServer from '../pages/EditPlaylistAndServer';
import NewCustomer from '../pages/NewCustomer';
import CustomerDetails from '../pages/CustomerDetails';
import EditPlaylistMain from '../pages/EditPlaylistMain';
import EditCustomerScheduler from '../pages/EditCustomerScheduler';
import EditCustomerPlaylist from '../pages/EditCustomerPlaylist';
import TagList from '../pages/TagList';

const isProtected = true;

export const ROUTES_DATA = [
  {
    path: '/',
    component: Login,
    exact: true,
  },
  {
    path: '/customer-list',
    component: CustomerList,
    exact: true,
    isProtected: isProtected,
  },
  {
    path: '/search-songs',
    component: SearchSongs,
    exact: true,
    isProtected: isProtected,
  },
  {
    path: '/add-songs',
    component: AddSongs,
    exact: true,
    isProtected: isProtected,
  },
  {
    path: '/royalties/edit-playlist-secondery',
    component: EditPlaylistSecondery,
    exact: true,
    isProtected: isProtected,
  },
  {
    path: '/non-royalties/edit-playlist-secondery',
    component: EditPlaylistSecondery,
    exact: true,
    isProtected: isProtected,
  },
  {
    path: '/royalties/edit-playlist-and-server',
    component: EditPlaylistAndServer,
    exact: true,
    isProtected: isProtected,
  },
  {
    path: '/non-royalties/edit-playlist-and-server',
    component: EditPlaylistAndServer,
    exact: true,
    isProtected: isProtected,
  },
  {
    path: '/new-customer',
    component: NewCustomer,
    exact: true,
    isProtected: isProtected,
  },
  {
    path: '/update-customer',
    component: NewCustomer,
    exact: true,
    isProtected: isProtected,
  },
  {
    path: '/customer-details',
    component: CustomerDetails,
    exact: true,
    isProtected: isProtected,
  },
  {
    path: '/royalties/edit-playlist-main',
    component: EditPlaylistMain,
    exact: true,
    isProtected: isProtected,
  },
  {
    path: '/non-royalties/edit-playlist-main',
    component: EditPlaylistMain,
    exact: true,
    isProtected: isProtected,
  },
  {
    path: '/edit-customer-scheduler',
    component: EditCustomerScheduler,
    exact: true,
    isProtected: isProtected,
  },
  {
    path: '/edit-customer-playlists',
    component: EditCustomerPlaylist,
    exact: true,
    isProtected: isProtected,
  },
  {
    path: '/tags',
    component: TagList,
    exact: true,
    isProtected: isProtected,
  },
];

export const OnlyPublicPathNames = ['/'];
