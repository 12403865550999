/**
 * Name: Edit Playlist
 * Author: @Gaurav
 * Date: 20-07-2021
 */
import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/SideBar';
import NavBar from '../../components/NavBarSearchSong';
import Settings from '../../assets/Settings.png';
import search from '../../assets/ic_search.png';
import RoundButton from '../../components/RoundButton';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PlayBtn from '../../assets/play_btn.svg';
import Delete from '../../assets/Delete.png';
import AlertModal from '../../components/Modal';
import { CircularProgress } from '@material-ui/core';
import { Grid } from '@mui/material';
import SongPlayer from '../../components/SongPlayer';

/* 
 Table Data and Use Style
 */
const useStyles = makeStyles({
  table: {
    minWidth: 372,
    borderRadius: 30,
  },
});

function EditPlaylist({
  isRoyalties,
  playlistToUpdate,
  onPlaylistTabClick,
  onUpdatePlaylist,
  selectedSongsToUpload,
  handleSelectSongsToUpload,
  handleMultipleSongsToUpload,
  handleRemoveSong,
  handleSearchInput,
  searchInput,
  allPlaylist,
  handleSelectPlaylist,
  selectedPlaylist,
  handlePlaySong,
  isSongLoading,
  isSongPlaying,
  currentPlayingSong,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [deleteSelectedPlaylist, setDeleteSelectedPlaylist] = useState(null);
  const [SelectedPlayBtn, setSelectedPlayBtn] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSongDialogOpen, setIsSongDialogOpen] = useState({
    id: 0,
    isOpen: false,
  });


  /**
   * Close Modal
   */
  const handleClose = () => {
    setOpen(false);
  };

  const filterAllOtherThanSelected = (playList) => {
    if (Array.isArray(playList) && playList.length) {
      const typeToFilter = isRoyalties ? 3 : 4;
      return playList.filter((item) => item.id !== playlistToUpdate.id && item.type === typeToFilter);
    }
    return [];
  };

  const getPlaylistSongsList = () => {
    if (Array.isArray(selectedPlaylist)) {
      let songsList = [];
      selectedPlaylist.map((item) => {
        songsList = [...songsList, ...item.songs];
      });
      return songsList;
    }
    return [];
  };

  const isAllSongSelected = () => {
    const currentPlaylistSongs = getPlaylistSongsList();

    if (!currentPlaylistSongs.length) return false;

    // check if each song is selected
    return currentPlaylistSongs.every((song) => selectedSongsToUpload.some((selectedSong) => selectedSong.id === song.id));
  };

  const handleRemoveSongLocal = (deleteSelectedPlaylist) => {
    handleRemoveSong(deleteSelectedPlaylist);
    setOpen(false);
  };
  const handleSelectedPlayBtn = (row) => {
    setIsPlaying(isSongPlaying && currentPlayingSong.id === row.id);
    setIsLoading(isSongLoading && currentPlayingSong.id === row.id);
    handlePlaySong(row);
    setSelectedPlayBtn(row);
  };

  useEffect(() => {
    setIsLoading(isSongLoading);
  }, [isSongLoading]);

  return (
    <div className="main">
      <div className="sidebar">
        <Sidebar />
      </div>
      <AlertModal open={open} onClose={handleClose} handelDelete={() => handleRemoveSongLocal(deleteSelectedPlaylist)} />
      <div className="leftPortion">
        <NavBar data="Edit Playlist" icon={Settings} currentRouteName="Playlist" breadcrumbsPath="Edit Playlist / Playlist" />
        <div className="content">
          <div className="warp_edit_row">
            <div className="editRow">
              <div className="wrap_edit">
                <p>Edit:</p>
                <RoundButton btnText="Playlist" />
                <RoundButton className="disabled-button" handleClick={() => onPlaylistTabClick('playlistServer')} btnText="server" />
              </div>

              <div className="search_row wdth">
                <input
                  className="input_field"
                  placeholder="Search something here..."
                  type="text"
                  value={searchInput}
                  onChange={(e) => handleSearchInput(e.target.value)}
                />
                <img src={search} />
              </div>
            </div>
            <div className="">
              <RoundButton btnText="Save" handleClick={onUpdatePlaylist} />
            </div>
          </div>

          <div className="main_tbl_wrap">
            <TableContainer
              className="tbl_wraper tbl_wrap1"
              style={{ border: '1px solid #40189D', borderRight: 'none', borderRadius: 0 }}
              component={Paper}
            >
              <Table style={{ borderTopRightRadius: 0 }} className={classes.table} stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox disabled inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} />
                    </TableCell>
                    <TableCell className="table_font">Result</TableCell>
                    <TableCell>Playlist Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterAllOtherThanSelected(allPlaylist).map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Checkbox
                          checked={selectedPlaylist.some((item) => item.id === row.id) || false}
                          onChange={(event) => handleSelectPlaylist(event, row)}
                          inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                        />
                      </TableCell>
                      <TableCell className="table-index-cell">#{++index}</TableCell>
                      <TableCell className="table-name-cell">{row.name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <Box display="flex" height="100%" alignItems="center">
              <div className="divider-line" />
            </Box> */}
            <TableContainer className="tbl_wraper tbl_wrap2" style={{ border: '1px solid #40189D' }} component={Paper}>
              <Table className={classes.table} stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                        onChange={(event) => handleMultipleSongsToUpload(event, getPlaylistSongsList())}
                        checked={isAllSongSelected()}
                      />
                    </TableCell>
                    <TableCell className="table_font">Result</TableCell>
                    <TableCell>Song Name</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getPlaylistSongsList()?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Checkbox
                          onChange={(event) => handleSelectSongsToUpload(event, row)}
                          inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                          checked={selectedSongsToUpload.some((item) => item.id === row.id) || false}
                          name="song"
                        />
                      </TableCell>
                      <TableCell className="table-index-cell">#{++index}</TableCell>
                      <TableCell className="table-name-cell">{row.fileName || row.name || 'N/A'}</TableCell>
                      <TableCell>
                        {isLoading && row?.id === currentPlayingSong?.id ? (
                          <CircularProgress />
                        ) : (
                          <div
                            style={{
                              position: 'relative',
                            }}
                          >
                            <Grid item >
                              {isSongDialogOpen.isOpen && isSongDialogOpen.id === row?.id ? (
                                <SongPlayer
                                  songDetail={row}
                                  onClose={() => {
                                    setIsSongDialogOpen({
                                      id: 0,
                                      isOpen: false,
                                    });
                                  }}
                                />
                              ) : (
                                <></>
                              )}
                            </Grid>

                            <img
                              src={PlayBtn}
                              alt=""
                              onClick={() =>
                                setIsSongDialogOpen({
                                  id: row.id,
                                  isOpen: true,
                                })
                              }
                            />
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer className="tbl_wraper  selectTbl" component={Paper}>
              <Table className={classes.table} stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell className="table_font">Result</TableCell>
                    <TableCell>Selected Songs</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(selectedSongsToUpload) &&
                    selectedSongsToUpload.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell className="table-index-cell">#{++index}</TableCell>
                        <TableCell className="table-name-cell">{row.title || row.fileName || row.name || 'N/A'}</TableCell>
                        <TableCell>
                          <img
                            className="remove-icon"
                            src={Delete}
                            onClick={() => {
                              setOpen(true);
                              setDeleteSelectedPlaylist(row);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
export default EditPlaylist;

