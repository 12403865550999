import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';

// Style
import './Popup.scss';

const Popup = ({ children, open, onClose }) => {
  return (
    <Dialog className="popup-container" open={open} onClose={onClose}>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default Popup;

