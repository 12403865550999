/**
 * Name: New customer
 * Author: @Gaurav
 * Date: 20-07-2021
 */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../../components/SideBar';
import NavBar from '../../components/NavBarSearchSong';
import Settings from '../../assets/Settings.png';
import email_icn from '../../assets/email.svg';
import call from '../../assets/call.svg';
import RoundButton from '../../components/RoundButton';
import '../NewCustomer/NewCustomer.scss';
import { _showAlert } from '../../utils';
import Dropdown from '../../components/Dropdown';
import { royalty } from '../../generalConstant';

function NewCustomer(props) {
  const dispatch = useDispatch();
  const { newCustomerData, status } = useSelector((state) => ({
    newCustomerData: state.newCustomer.result,
    status: state.newCustomer.status,
  }));

  const [isLoading, setIsLoading] = useState(false);

  const defaultFormState = {
    firstname: '',
    lastname: '',
    business_name: '',
    username: '',
    password: '',
    phone: '',
    email: '',
    address: '',
  };
  const [formFields, setFormFields] = useState(defaultFormState);
  const [togglePassword, setTogglePassword] = useState(true);
  const [errors, setErrors] = useState();
  const [readoOnlyFields, setReadOnlyFields] = useState({
    username: true,
    password: true,
  });
  const [isRoyalty, setIsRoyalty] = useState(royalty[0]);
  let isRoyaltyValue = isRoyalty?.value === 'true';

  /**
   * it will handle the validation.
   */
  const handleValidation = () => {
    let fields = formFields;
    let errors = {};
    let formIsValid = true;

    //firstname
    if (!fields['firstname']) {
      formIsValid = false;
      errors['firstname'] = 'Cannot be empty';
    }
    //lastname
    if (!fields['lastname']) {
      formIsValid = false;
      errors['lastname'] = 'Cannot be empty';
    }
    //business_name
    if (!fields['business_name']) {
      formIsValid = false;
      errors['business_name'] = 'Cannot be empty';
    }
    //username
    if (!formFields.id) {
      if (!fields['username']) {
        formIsValid = false;
        errors['username'] = 'Cannot be empty';
      }
    }
    //pasword
    if (!formFields.id) {
      if (!fields['password']) {
        formIsValid = false;
        errors['password'] = 'Cannot be empty';
      }
    }
    //phone
    if (!fields['phone']) {
      formIsValid = false;
      errors['phone'] = 'Cannot be empty';
    }
    //email
    if (!fields['email']) {
      formIsValid = false;
      errors['email'] = 'Cannot be empty';
    }
    //address
    if (!fields['address']) {
      formIsValid = false;
      errors['address'] = 'Cannot be empty';
    }
    setErrors(errors);
    return formIsValid;
  };

  const handleInputChange = (event) => {
    let id = event.target.id;
    let value = event.target.value;
    setFormFields({ ...formFields, [id]: value });
  };

  /**
   * it will handle the new customer api request.
   */
  const handleNewCustomer = () => {
    if (handleValidation()) {
      let payload = {
        firstname: formFields.firstname,
        lastname: formFields.lastname,
        business_name: formFields.business_name,
        phone: formFields.phone,
        email: formFields.email,
        address: formFields.address,
        royalty_only: isRoyaltyValue,
      };
      if (formFields.id) {
        payload['id'] = formFields.id;
      }
      if (formFields.username) {
        payload['username'] = formFields.username;
      }
      if (formFields.password) {
        payload['password'] = formFields.password;
      }
      dispatch({
        type: 'NEW_CUSTOMER',
        payload,
      });
    }
  };

  useEffect(() => {
    if (status === 'initiated') {
      setIsLoading(true);
    } else if (newCustomerData && status === 'success') {
      setFormFields(defaultFormState);
      setIsLoading(false);
      _showAlert('Customer Details Submitted Successfully', 'success', 4000);
      props.history.push('/customer-list');
    } else if (newCustomerData && status === 'failed') {
      setIsLoading(false);
      _showAlert('Something went wrong', 'error', 4000);
      // props.history.push('/customer-list');
    } else {
      setIsLoading(false);
    }
  }, [newCustomerData, status]);

  useEffect(() => {
    if (props?.history?.location?.state) {
      setFormFields({ ...formFields, ...props.history.location.state });
      setIsRoyalty(props?.history?.location?.state?.royalty_only);
    }
  }, [props.history]);

  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="leftPortion">
          <NavBar
            data={formFields.id ? 'Update Customer' : 'New Customer'}
            icon={Settings}
            currentRouteName="Customers"
            breadcrumbsPath={formFields.id ? 'Update Customer' : 'New Customer'}
          />
          <div className="content">
            <div className="btn_row">
              <RoundButton disabled={isLoading} btnText={isLoading ? 'Loading...' : 'Save'} handleClick={handleNewCustomer} />
            </div>
            <div className="new_customer_row">
              <h5>General</h5>
              <div className="field_row">
                <div className="field_col">
                  <label>First Name</label>
                  <input type="text" value={formFields.firstname} onChange={handleInputChange} id="firstname" />
                  <span className="input-error-messages">{errors && errors['firstname']}</span>
                </div>
                <div className="field_col">
                  <label>Last Name</label>
                  <input type="text" value={formFields.lastname} onChange={handleInputChange} id="lastname" />
                  <span className="input-error-messages">{errors && errors['lastname']}</span>
                </div>
                <div className="field_col">
                  <label>Business Name</label>
                  <input type="text" value={formFields.business_name} onChange={handleInputChange} id="business_name" />
                  <span className="input-error-messages">{errors && errors['business_name']}</span>
                </div>
                <div className="field_col">
                  <label>Username</label>
                  <input
                    type="text"
                    value={formFields.username}
                    onChange={handleInputChange}
                    id="username"
                    readOnly={readoOnlyFields.username}
                    onFocus={() => setReadOnlyFields({ ...readoOnlyFields, username: false })}
                  />
                  <span className="input-error-messages">{errors && errors['username']}</span>
                </div>
                <div className="field_col">
                  <label>Password</label>
                  <input
                    type={togglePassword ? 'password' : 'text'}
                    value={formFields.password}
                    onChange={handleInputChange}
                    id="password"
                    readOnly={readoOnlyFields.password}
                    onFocus={() => setReadOnlyFields({ ...readoOnlyFields, password: false })}
                  />
                  <span role="button" onClick={() => setTogglePassword(!togglePassword)} className="show_password">
                    Show
                  </span>
                  <span className="input-error-messages">{errors && errors['password']}</span>
                </div>
                <div className="field_col">
                  <label>Customer Type</label>
                  <Dropdown options={royalty} value={isRoyalty} setValue={setIsRoyalty} />
                </div>
              </div>

              <h5>contact</h5>

              <div className="field_row">
                <div className="field_col icn_field">
                  <label>MobilePhone</label>
                  <input type="text" value={formFields.phone} onChange={handleInputChange} id="phone" />
                  <img src={call} />
                  <span className="input-error-messages">{errors && errors['phone']}</span>
                </div>
                <div className="field_col icn_field">
                  <label>Email</label>
                  <input type="text" value={formFields.email} onChange={handleInputChange} id="email" />
                  <img src={email_icn} />
                  <span className="input-error-messages">{errors && errors['email']}</span>
                </div>
              </div>

              <div className="field_row">
                <div className="field_col">
                  <label>Address</label>
                  <input
                    type="text"
                    placeholder="Franklin Avenue St. Corner"
                    value={formFields.address}
                    onChange={handleInputChange}
                    id="address"
                  />
                  <span className="input-error-messages">{errors && errors['address']}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewCustomer;

