/**
 * Name: Edit Customer Playlist
 * Author: @Gaurav
 * Date: 19-07-2021
 */
import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/SideBar';
import { useDispatch, useSelector } from 'react-redux';
import NavBar from '../../components/NavBarSearchSong';
import Settings from '../../assets/Settings.png';
import RoundButton from '../../components/RoundButton';
import search from '../../assets/ic_search.png';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Delete from '../../assets/Delete.png';
import './EditCustomerPlaylist.scss';
import { _showAlert, formatDateToDisplay } from '../../utils';
import AlertModal from '../../components/Modal';
import { colorIsDarkSimple } from '../../generalConstant';

const useStyles = makeStyles({
  table: {
    maxHeight: '90vh',
    borderRadius: 30,
  },
});

function EditCustomer(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [selectedPlaylistToUpload, setPlaylistToUpload] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const [isRoyalties, setIsRoyalties] = useState(false);

  /**
   * Close Modal
   */
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * getting state from redux store
   */
  const { getAllPlaylist, deleteCustomerPlaylist, addCustomerPlaylist, getCustomerPlaylist } = useSelector((state) => ({
    getAllPlaylist: state.getAllPlaylist.result,
    deleteCustomerPlaylist: state.deleteCustomerPlaylist.result,
    addCustomerPlaylist: state.addCustomerPlaylist.result,
    getCustomerPlaylist: state.getCustomerPlaylist.result,
  }));
  /**
   * call the get playlist api
   * watch the local state searchInput changes to search the playlist
   */
  useEffect(() => {
    dispatch({ type: 'GET_ALL_PLAYLIST', payload: searchInput });
  }, [searchInput]);

  /**
   * check the history object
   */
  useEffect(() => {
    if (props?.history?.location?.state) {
      setSelectedCustomer(props.history.location.state);
      const customer = props.history.location.state;
      if (customer.royalty_only) setIsRoyalties(true);
    }
  }, [props.history]);

  /**
   * call added playlist get api
   */
  useEffect(() => {
    if (selectedCustomer.id) {
      dispatch({ type: 'GET_CUSTOMER_PLAYLIST', payload: { customerId: selectedCustomer.id } });
    }
  }, [selectedCustomer]);

  /**
   * watch add playlist api request
   */
  useEffect(() => {
    if (addCustomerPlaylist?.status === 200) {
      dispatch({ type: 'GET_CUSTOMER_PLAYLIST', payload: { customerId: selectedCustomer.id } });
      _showAlert('Added Successfully', 'success');
      setPlaylistToUpload([]);
    }
    if (addCustomerPlaylist?.error) {
      _showAlert(addCustomerPlaylist?.error, 'error', 8000);
    }
  }, [addCustomerPlaylist]);

  /**
   * watch delete customer p[laylist api request
   */
  useEffect(() => {
    if (deleteCustomerPlaylist?.status === 200) {
      dispatch({ type: 'GET_CUSTOMER_PLAYLIST', payload: { customerId: selectedCustomer.id } });
      _showAlert('Deleted Successfully', 'success');
    }
    if (deleteCustomerPlaylist?.error) {
      _showAlert(addCustomerPlaylist?.error, 'error', 8000);
    }
  }, [deleteCustomerPlaylist]);

  const filterPlaylistsByType = (playlists, type) => {
    return Array.isArray(playlists) ? playlists.filter((item) => item.type === type) : [];
  };

  const checkPlaylist = () => {
    const allType = isRoyalties ? 3 : 4;

    const customerPlaylist = Array.isArray(getCustomerPlaylist) ? getCustomerPlaylist.map((item) => item.id) : [];
    return filterPlaylistsByType(getAllPlaylist, allType).filter((item) => !customerPlaylist.includes(item.id));
  };
  const handleEditCustomerScheduler = () => {
    props.history.push({
      pathname: '/edit-customer-scheduler',
      state: selectedCustomer,
    });
  };

  const handleAddPlaylistToCustomer = () => {
    const playlists = selectedPlaylistToUpload.map((item) => item.id);
    dispatch({ type: 'ADD_CUSTOMER_PLAYLIST', payload: { customerId: selectedCustomer.id, playlists } });
  };

  const handleDeleteCustomerPlaylist = (playlist) => {
    dispatch({ type: 'DELETE_CUSTOMER_PLAYLIST', payload: { customerId: selectedCustomer.id, playlists: [playlist.id] } });
    setOpen(false);
  };

  const handleSelectSongsToUpload = (event, playlist) => {
    let checked = event.target.checked;
    if (!playlist) {
      if (checked) {
        setPlaylistToUpload([...checkPlaylist()]);
      } else {
        setPlaylistToUpload([]);
      }
    } else {
      if (checked) {
        setPlaylistToUpload([...selectedPlaylistToUpload, playlist]);
      } else {
        setPlaylistToUpload(selectedPlaylistToUpload.filter((item) => item.id !== playlist.id));
      }
    }
  };

  const getCustomerName = (customer) => {
    if (customer.name) {
      return customer.name;
    } else {
      let name = '';
      name = customer.firstname || '';
      name = name + ' ' + customer.lastname || '';
      return name || 'N/A';
    }
  };

  return (
    <div className="main">
      <div className="sidebar">
        <Sidebar />
      </div>
      <AlertModal open={open} onClose={handleClose} handelDelete={() => handleDeleteCustomerPlaylist(selectedPlaylist)} />
      <div className="leftPortion">
        <NavBar data="All Customers" icon={Settings} currentRouteName="Customers" breadcrumbsPath="Edit Customer Playlists" />
        <div className="content">
          <div className="warp_edit_row">
            <div className="editRow">
              <div className="wrap_edit">
                <p>Edit:</p>
                <RoundButton btnText="Playlists" />
                <RoundButton className="disabled-button" btnText="Scheduler" handleClick={handleEditCustomerScheduler} />
                <div className="search_row search_playlist">
                  <input
                    className="input_field"
                    placeholder="Search something here..."
                    type="text"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                  <img src={search} />
                </div>
                <RoundButton btnText="Royalties" className={isRoyalties ? '' : 'not-selected'} handleClick={() => setIsRoyalties(true)} />
                <RoundButton
                  btnText="unRoyalties"
                  className={!isRoyalties ? '' : 'not-selected'}
                  handleClick={() => setIsRoyalties(false)}
                />
              </div>
            </div>
          </div>
          <TableContainer className="tbl_wraper" component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Royalty Type</TableCell>
                  <TableCell>Business Name</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{getCustomerName(selectedCustomer)}</TableCell>
                  <TableCell>{selectedCustomer.royalty_only ? 'Royalty' : 'Free (UnRoyalty)'}</TableCell>
                  <TableCell>{selectedCustomer.business_name || 'N/A'}</TableCell>
                  <TableCell>{selectedCustomer.address || 'N/A'}</TableCell>
                  <TableCell>{selectedCustomer.email}</TableCell>
                  <TableCell>{formatDateToDisplay(selectedCustomer.createdAt)}</TableCell>
                  <TableCell>
                    <span className="green_circle"></span>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div className="wrap_tbl remove_padding">
            <TableContainer className="tbl_wraper result_tbl" component={Paper}>
              <Table className={classes.table} stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        checked={checkPlaylist().length && checkPlaylist().length === selectedPlaylistToUpload.length ? true : false}
                        onChange={(event) => handleSelectSongsToUpload(event)}
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    </TableCell>
                    <TableCell>Result</TableCell>
                    <TableCell>Playlist Name</TableCell>
                    <TableCell>Tags</TableCell>

                    <TableCell>
                      <RoundButton handleClick={handleAddPlaylistToCustomer} btnText="Add" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {checkPlaylist().map((playlist, index) => (
                    <TableRow key={playlist.name}>
                      <TableCell>
                        <Checkbox
                          checked={selectedPlaylistToUpload.some((item) => item.id == playlist.id)}
                          onChange={(event) => handleSelectSongsToUpload(event, playlist)}
                          color="primary"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>#{++index}</TableCell>
                      <TableCell>{playlist.name}</TableCell>
                      <TableCell
                        style={{
                          minWidth: '150px',
                        }}
                      >

                        <div
                          style={{
                            display: 'flex',
                            maxWidth: '150px',
                            flexWrap: 'wrap',
                          }}
                        >
                          {playlist.tags && playlist.tags.length > 0 ? (
                            playlist.tags.map((tag) => (
                              <div
                                className="tag_box"
                                key={tag.id}
                                style={{
                                  background: tag.color,
                                  color: colorIsDarkSimple(tag.color) ? 'white' : 'black',
                                  maxWidth: '150px',
                                  height: '24px',
                                  overflow: 'hidden',
                                }}
                              >

                                {tag.name.length > 18 ? tag.name.slice(0, 18) + '...' : tag.name}
                              </div>
                            ))
                          ) : (
                            <div
                            >
                              -
                            </div>
                          )}
                        </div>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer
              style={{
                width: '40%',
              }}
              className="tbl_wraper result_playlist" component={Paper}>
              <Table className={classes.table} stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Result</TableCell>
                    <TableCell>Playlist Selected</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(getCustomerPlaylist) &&
                    getCustomerPlaylist.map((playlist, index) => (
                      <TableRow key={playlist.id}>
                        <TableCell style={{ fontWeight: 'bold' }}>#{++index}</TableCell>
                        <TableCell>{playlist.name || 'N/A'}</TableCell>
                        <TableCell>
                          <img
                            onClick={() => {
                              setOpen(true);
                              setSelectedPlaylist(playlist);
                            }}
                            src={Delete}
                            alt="delete"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditCustomer;

