/**
 * Name: Edit Playlist Page
 * Author: @Gaurav
 * Date: 20-07-2021
 */
import React, { useState, useEffect, useRef } from 'react';
import './EditPlaylistAndServer.scss';
import { useLocation } from 'react-router-dom';
import EditPlaylist from './EditPlaylist';
import EditPlaylistServer from './EditPlaylistServer';
import { _showAlert } from '../../utils';
import { LoaderSpinner } from '../../components';
import { useDispatch, useSelector } from 'react-redux';

function EditPlaylistAndServer(props) {
  const routeLocation = useLocation();
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState('playlistTabs');
  const [playlistToUpdate, setListToUpdate] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [selectedSongsToUpload, setSongsToUpload] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [selectedPlaylist, setSelectedPlaylist] = useState([]);
  const [currentPlayingSong, setCurrentPlayingSong] = useState(null);
  const [isSongPlaying, setIsSongPlaying] = useState(false);
  const [isSongLoading, setIsSongLoading] = useState(false);

  const {
    updatePlaylist,
    getAllPlaylist: allPlaylist,
    songURL,
    songsList,
  } = useSelector((state) => ({
    updatePlaylist: state.updatePlaylist.result,
    getAllPlaylist: state.getAllPlaylist.result,
    songURL: state.getSongURL.result,
    songsList: state.searchSongs.result,
  }));

  const audioRef = useRef(null);

  const handlePlaylistTabClick = (tabName = {}) => {
    setSelectedTab(tabName);
    setSearchInput('');
  };

  const urlData = props.location.pathname.split('/');
  const isRoyalties = urlData.includes('royalties');


  useEffect(() => {
    if (selectedTab === 'playlistTabs') {
      dispatch({ type: 'GET_ALL_PLAYLIST', payload: searchInput });
    } else {
      dispatch({ type: 'SEARCH_SONGS', payload: searchInput });
    }
  }, [searchInput, selectedTab]);

  useEffect(() => {
    if (routeLocation?.state?.selectedPlaylist) {
      let playlist = routeLocation.state.selectedPlaylist;
      setListToUpdate(playlist);
      setSongsToUpload(playlist?.songs);
    }
  }, [routeLocation]);

  useEffect(() => {
    if (updatePlaylist.id) {
      setLoading(false);
      _showAlert('Playlist Successfully Updated', 'success');
      props.history.push(
        // '/edit-playlist-main'
        isRoyalties ? '/royalties/edit-playlist-main' : '/non-royalties/edit-playlist-main'
      );
    }
    if (updatePlaylist.error) {
      setLoading(false);
      _showAlert(updatePlaylist.error, 'error');
    }
  }, [updatePlaylist]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'CLEAR_STATE', actionName: 'UPDATE_PLAYLIST' });
    };
  }, []);

  useEffect(() => {
    if (songURL.status === 200 && songURL.url) {
      window.open(songURL.url, '_blank');
      dispatch({ type: 'CLEAR_STATE', actionName: 'GET_SONG_URL' });
    }
    if (songURL?.error) {
      _showAlert(songURL?.error, 'error');
    }
  }, [songURL]);

  const handleUpdatePlaylist = () => {
    setLoading(true);
    let songs = selectedSongsToUpload.map((item, index) => ({ id: item.id, index }));
    dispatch({ type: 'UPDATE_PLAYLIST', payload: { id: playlistToUpdate.id, name: playlistToUpdate.name, songs } });
  };

  const handleMultipleSongsToUpload = (event, songlist) => {
    let checked = event.target.checked;
    if (checked) {
      const newSongs = [...selectedSongsToUpload, ...songlist];
      const uniqueSongsMap = new Set(newSongs.map((item) => item.id));
      const uniqueSongs = Array.from(uniqueSongsMap).map((id) => newSongs.find((item) => item.id === id));
      setSongsToUpload(uniqueSongs);
    } else {
      const newSongs = selectedSongsToUpload.filter((item) => !songlist.some((song) => song.id === item.id));
      setSongsToUpload(newSongs);
    }
  };

  const handleSelectSongsToUpload = (event, song) => {
    let checked = event.target.checked;
    if (checked) {
      setSongsToUpload([...selectedSongsToUpload, song]);
    } else {
      setSongsToUpload(selectedSongsToUpload.filter((item) => item.id !== song.id));
    }
  };

  const handleRemoveSong = (song) => {
    setSongsToUpload(selectedSongsToUpload.filter((item) => item.id !== song.id));
  };

  const handleSelectPlaylist = (event, playList) => {
    let checked = event.target.checked;
    if (checked) {
      setSelectedPlaylist([...selectedPlaylist, playList]);
    } else {
      let newList = selectedPlaylist.filter((item) => item.id !== playList.id);
      setSelectedPlaylist(newList);
    }
  };

  const handlePlaySong = async (song) => {
    // dispatch({ type: 'GET_SONG_URL', payload: `${song.id}.mp3` });
    console.log('song', audioRef);
    if (!audioRef.current) {
      // show error;
      console.log('error');
      return;
    }
    const prevSong = currentPlayingSong;
    setCurrentPlayingSong(song);

    if (prevSong?.id === song.id) {
      if (isSongPlaying) {
        audioRef.current.pause();
        setIsSongPlaying(false);
        return;
      }
      setIsSongLoading(true);
      audioRef.current.src = `${process.env.REACT_APP_API_URL}/songs/${song.id}.mp3`;
      audioRef.current.play().then(() => {
        console.log('playing');
        setIsSongLoading(false);
        setIsSongPlaying(true);
      });
      return;
    }

    if (isSongPlaying) {
      audioRef.current.pause();
      setIsSongPlaying(false);
    }

    setIsSongLoading(true);
    audioRef.current.src = `${process.env.REACT_APP_API_URL}/songs/${song.id}.mp3`;
    audioRef.current.play().then(() => {
      setIsSongLoading(false);
      setIsSongPlaying(true);
    });
  };

  return (
    <>
      <audio ref={audioRef} crossorigin="anonymous" />
      {isLoading && <LoaderSpinner />}
      {selectedTab === 'playlistTabs' ? (
        <EditPlaylist
          isRoyalties={isRoyalties}
          onUpdatePlaylist={handleUpdatePlaylist}
          onPlaylistTabClick={handlePlaylistTabClick}
          playlistToUpdate={playlistToUpdate}
          selectedSongsToUpload={selectedSongsToUpload}
          handleSelectSongsToUpload={handleSelectSongsToUpload}
          handleMultipleSongsToUpload={handleMultipleSongsToUpload}
          handleRemoveSong={handleRemoveSong}
          handleSearchInput={setSearchInput}
          searchInput={searchInput}
          allPlaylist={allPlaylist}
          selectedPlaylist={selectedPlaylist}
          handleSelectPlaylist={handleSelectPlaylist}
          handlePlaySong={handlePlaySong}
          isSongPlaying={isSongPlaying}
          isSongLoading={isSongLoading}
          currentPlayingSong={currentPlayingSong}
          {...props}
        />
      ) : (
        <EditPlaylistServer
          onUpdatePlaylist={handleUpdatePlaylist}
          onPlaylistTabClick={handlePlaylistTabClick}
          selectedSongsToUpload={selectedSongsToUpload}
          handleSelectSongsToUpload={handleSelectSongsToUpload}
          handleMultipleSongsToUpload={handleMultipleSongsToUpload}
          handleRemoveSong={handleRemoveSong}
          handleSearchInput={setSearchInput}
          searchInput={searchInput}
          handlePlaySong={handlePlaySong}
          songsList={songsList}
          {...props}
        />
      )}
    </>
  );
}

export default EditPlaylistAndServer;

