import { put, takeLatest, call } from 'redux-saga/effects';
import ApiCaller from '../ApiCaller';

const callAPI = (payload) => {
  return ApiCaller(`/v1/admin/playlist/${payload.playlistId}/tags`, 'DELETE', { tags_ids: payload.tagIds });
};
export default function* watchRequest() {
  yield takeLatest('DELETE_PLAYLIST_TAG', function* performAction(action) {
    yield put({ type: 'DELETE_PLAYLIST_TAG_INITIATED' });
    try {
      const data = yield call(callAPI.bind(this, action.payload));
      yield put({ type: 'DELETE_PLAYLIST_TAG_SUCCESS', payload: data });
    } catch (error) {
      yield put({ type: 'DELETE_PLAYLIST_TAG_FAILED', payload: error });
    }
  });
}

