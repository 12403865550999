/**
 * Name: Combine Reducer
 * Author: @Gaurav
 * Date: 19-07-2021
 */
import { combineReducers } from 'redux';
import login from './LoginUser';
import addNewSongs from './AddNewSongs';
import newPlaylist from './AdminNewPlaylist';
import newCustomer from './AdminNewCustomer';
import customerList from './CustomerList';
import searchSongs from './SearchSongs';
import deleteSongs from './DeleteSongs';
import getAllPlaylist from './GetAllPlaylist';
import deletePlaylist from './DeletePlaylist';
import updatePlaylist from './UpdatePlaylist';
import editCustomerId from './EditCustomer';
import deleteCustomerPlaylist from './DeleteCustomerPlaylist';
import getCustomerPlaylist from './GetCustomerPlaylist';
import addCustomerPlaylist from './AddCustomerPlaylist';
import getSongURL from './GetSongURL';
import scheduleCustomerSong from './ScheduleCustomerSong';
import deleteCustomerSchedule from './DeleteCustomerSchedule';
import tagList from './TagList';
import addTag from './AddTag';
import editTag from './EditTag';
import deleteTag from './DeleteTag';
import addPlaylistTag from './AddPlaylistTag';
import deletePlaylistTag from './DeletePlaylistTag';

export default combineReducers({
  login,
  addNewSongs,
  newPlaylist,
  newCustomer,
  customerList,
  searchSongs,
  deleteSongs,
  getAllPlaylist,
  deletePlaylist,
  updatePlaylist,
  editCustomerId,
  deleteCustomerPlaylist,
  getCustomerPlaylist,
  addCustomerPlaylist,
  getSongURL,
  scheduleCustomerSong,
  deleteCustomerSchedule,
  tagList,
  addTag,
  editTag,
  deleteTag,
  addPlaylistTag,
  deletePlaylistTag,
});
