/**
 * Name: Edit Playlists Main
 * Author: @Gaurav
 * Date: 20-07-2021
 */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../../components/SideBar';
import NavBar from '../../components/NavBarSearchSong';
import Settings from '../../assets/Settings.png';
import search from '../../assets/ic_search.png';
import CopyIcn from '../../assets/copy_icn.svg';
import EditIcn from '../../assets/edit_icn.svg';
import Delete from '../../assets/Delete.png';
// import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import '../../pages/EditPlaylistMain/EditPlaylistMain.scss';
import { _showAlert, formatDateToDisplay } from '../../utils';
import { LoaderSpinner } from '../../components';
import AlertModal from '../../components/Modal';
import DownloadIcon from '../../components/Svgs/DownloadIcon.svg';
import RoundButton from '../../components/RoundButton';
import Popup from '../../components/Popup';
import ApiCaller from '../../redux/ApiCaller';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom/';
import { Autocomplete, TextField } from '@mui/material';
import { colorIsDarkSimple } from '../../generalConstant';

/**
 * style for material ui table
 */
const useStyles = makeStyles({
  table: {
    minWidth: 700,
    borderRadius: 30,
  },
});

function EditPlaylistMain(props) {
  const classes = useStyles();

  /**
   * getting state from redux store
   */
  const { getAllPlaylist, deletePlaylist, newPlaylistRequestData, tagList } = useSelector((state) => ({
    getAllPlaylist: state.getAllPlaylist.result,
    deletePlaylist: state.deletePlaylist.result,
    newPlaylistRequestData: state.newPlaylist.result,
    tagList: state.tagList.result,
  }));

  const dispatch = useDispatch();
  const history = useHistory();

  const [searchInput, setSearchInput] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [deleteSelectedPlaylist, setDeleteSelectedPlaylist] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [playlistName, setPlaylistName] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);

  //* for downloading playlist
  const [downloadingPlaylistIndexes, setDownloadingPlaylistIndexes] = useState([]);

  // get URL data one of /royalties/edit-playlist-main or /non-royalties/edit-playlist-main
  const urlData = props.location.pathname.split('/');
  const isRoyalties = urlData.includes('royalties');

  /**
   * Close Modal
   */
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * handle the delete api request
   * watch the changes of the deletePlaylist state
   */
  useEffect(() => {
    if (deletePlaylist.status === 200) {
      setLoading(false);
      _showAlert('Deleted successfully.', 'success');
      dispatch({ type: 'GET_ALL_PLAYLIST', payload: searchInput });
    }
    if (deletePlaylist.error) {
      setLoading(false);
      _showAlert(deletePlaylist.error, 'error');
    }
  }, [deletePlaylist]);

  /**
   * handle the get playlist api request
   * watch the changes of the getAllPlaylist state
   */
  useEffect(() => {
    if (getAllPlaylist.error) {
      _showAlert(getAllPlaylist.error, 'error');
    }
    setLoading(false);
  }, [getAllPlaylist]);

  /**
   * call the get playlist api
   * watch the local state searchInput changes to search the playlist
   */

  useEffect(() => {
    dispatch({ type: 'TAG_LIST' });
  }, []);

  useEffect(() => {
    dispatch({ type: 'GET_ALL_PLAYLIST', payload: searchInput, tagIds: selectedTags });
  }, [searchInput, selectedTags]);

  /**
   * handle the duplicate new playlist api call
   * watch the changes of the newPlaylistRequestData redux state
   */
  useEffect(() => {
    if (newPlaylistRequestData && newPlaylistRequestData.id) {
      _showAlert('Playlist has been created successfully.', 'success');
      dispatch({ type: 'GET_ALL_PLAYLIST', payload: '' });
    }
    if (newPlaylistRequestData.error) {
      setLoading(false);
      _showAlert(newPlaylistRequestData.error, 'error', 10000);
    }
  }, [newPlaylistRequestData]);

  /**
   * clear the redux state of the api called in this component while component unmount
   */
  useEffect(() => {
    return () => {
      dispatch({ type: 'CLEAR_STATE', actionName: 'NEW_PLAYLIST' });
      dispatch({ type: 'CLEAR_STATE', actionName: 'DELETE_PLAYLIST' });
    };
  }, []);

  /**
   * will get called on click of edit icon
   */
  const handleEditClick = (selectedPlayList) => {
    props.history.push({
      pathname: isRoyalties ? '/royalties/edit-playlist-secondery' : '/non-royalties/edit-playlist-secondery',
      state: { selectedPlayList },
    });
  };

  /**
   * will get called on click of delete icon of row
   * will call the delete playlist api
   */

  const handleDelete = (item) => {
    setOpen(false);
    setLoading(true);
    dispatch({ type: 'DELETE_PLAYLIST', payload: { ids: [item.id] } });
  };
  /**
   * It will arrange the list according to date
   */
  function songs_sort(a, b) {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  }

  /**
   * will get called on click of duplicate icon of row
   * will call the new playlist api with selected playlist name and songs
   */
  const handleDuplicate = (playlist) => {
    setLoading(true);
    dispatch({ type: 'NEW_PLAYLIST', payload: { name: playlist.name, songs: playlist.songs } });
  };

  const downloadPlaylist = async (playlist, index) => {
    const updatedDownloadingIndexs = [...downloadingPlaylistIndexes, index];
    setDownloadingPlaylistIndexes(updatedDownloadingIndexs);

    _showAlert('Your playlist is downloading', 'success');
    await ApiCaller(`/v1/admin/playlists/${playlist.id}/download`, 'GET')
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${playlist.name}.zip`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        _showAlert(error.message, 'error');
      })
      .finally(() => {
        const updatedDownloadingIndexs = [...downloadingPlaylistIndexes];
        updatedDownloadingIndexs.splice(updatedDownloadingIndexs.indexOf(index), 1);
        setDownloadingPlaylistIndexes(updatedDownloadingIndexs);
      });
  };

  const filteredPlaylists = Array.isArray(getAllPlaylist)
    ? getAllPlaylist?.filter((playlist) => (isRoyalties ? playlist.type === 3 : playlist.type === 4))
    : [];

  //* New Playlist
  const handleCreatePlaylist = () => {
    setIsCreating(true);
    dispatch({ type: 'NEW_PLAYLIST', payload: { name: playlistName, songs: [], type: isRoyalties ? 3 : 4 } });
    setIsCreating(false);
    setIsPopupOpen(false);
  };

  useEffect(() => {
    // clear the state of new playlist while closing the component
    return () => {
      dispatch({ type: 'CLEAR_STATE', actionName: 'NEW_PLAYLIST' });
    };
  }, []);

  return (
    <div className="main edit-playlist-main">
      {isLoading && <LoaderSpinner />}
      <div className="sidebar">
        <Sidebar />
      </div>
      <AlertModal open={open} onClose={handleClose} handelDelete={() => handleDelete(deleteSelectedPlaylist)} />
      <div className="leftPortion">
        <NavBar data="Edit Playlists" icon={Settings} currentRouteName="Playlists" breadcrumbsPath="Edit Playlists" />
        <div className="content">
          <div className="filtersMain">
            <div className="upperRow">
              <div className="search_row">
                <input
                  className="input_field"
                  placeholder="Search something here..."
                  type="text"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <img src={search} />
              </div>
              <div className="btn-group">
                <RoundButton
                  btnText="Royalties"
                  className={isRoyalties ? '' : 'not-selected'}
                  handleClick={() => {
                    history.push('/royalties/edit-playlist-main');
                  }}
                />
                <RoundButton
                  btnText="unRoyalties"
                  className={!isRoyalties ? '' : 'not-selected'}
                  handleClick={() => {
                    history.push('/non-royalties/edit-playlist-main');
                  }}
                />
              </div>
              <Autocomplete
                style={{ width: 250, marginLeft: 10 }}
                size="small"
                multiple
                limitTags={2}
                id="tags-outlined"
                options={tagList?.tags || []}
                getOptionLabel={(option) => option?.name}
                defaultValue={[]}
                filterSelectedOptions
                onChange={(event, newValue) => {
                  const selectedTagIds = newValue?.map((tag) => tag.id);
                  setSelectedTags(selectedTagIds);
                }}
                renderInput={(params) => <TextField {...params} placeholder="Tags" />}
              />
            </div>
            <div className="add-btn">
              <RoundButton btnText="New Playlist" handleClick={() => setIsPopupOpen(true)} />
            </div>
          </div>

          <TableContainer className="tbl_wraper" component={Paper}>
            <Table className={classes.table} stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>{/* <Checkbox inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} /> */}</TableCell>
                  <TableCell className="table_font">Result</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Playlist Name</TableCell>
                  <TableCell>Tags</TableCell>
                  <TableCell className="text_center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(getAllPlaylist)
                  ? filteredPlaylists.sort(songs_sort).map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{/* <Checkbox inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} /> */}</TableCell>
                      <TableCell align="justify" style={{ fontWeight: 'bold' }}>
                        <p style={{ paddingTop: '20px' }}>#{++index}</p>
                        {row.image ? (
                          <img
                            src={`${process.env.REACT_APP_API_URL}/playlists_images/${row.image}`}
                            crossorigin="anonymous"
                            alt="not-found"
                            className="imageContainerGet"
                          />
                        ) : (
                          <div className="thumbNail">
                            <p>{row.name?.[0]}</p>
                          </div>
                        )}
                      </TableCell>
                      <TableCell>{formatDateToDisplay(row.createdAt)}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell

                      >
                        <div
                          style={{
                            display: 'flex',
                            maxWidth: '200px',
                            flexWrap: 'wrap',
                          }}
                        >
                          {row.tags && row.tags.length > 0 ? (
                            row.tags.map((tag) => (
                              <div
                                className="tag_box"
                                key={tag.id}
                                style={{
                                  background: tag.color,
                                  color: colorIsDarkSimple(tag.color) ? 'white' : 'black',
                                  maxWidth: '200px',
                                  height: '24px',
                                  overflow: 'hidden',
                                }}
                              >

                                {tag.name.length > 25 ? tag.name.slice(0, 25) + '...' : tag.name}
                              </div>
                            ))
                          ) : (
                            <div

                            >
                              -
                            </div>
                          )}
                        </div>
                      </TableCell>
                      <TableCell>
                        <span className="action_icn">
                          {downloadingPlaylistIndexes.includes(index) ? (
                            <CircularProgress size={20} />
                          ) : (
                            <DownloadIcon className="icon" onClick={() => downloadPlaylist(row, index)} />
                          )}
                          <img alt="" onClick={() => handleDuplicate(row)} src={CopyIcn} />
                          <img alt="" src={EditIcn} onClick={() => handleEditClick(row)} />
                          <img
                            alt=""
                            onClick={() => {
                              setOpen(true);
                              setDeleteSelectedPlaylist(row);
                            }}
                            src={Delete}
                          />
                        </span>
                      </TableCell>
                    </TableRow>
                  ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      {
        <Popup open={isPopupOpen} onClose={() => setIsPopupOpen(false)}>
          <div className="create_playlist">
            <p className="header">New Playlist</p>
            <input placeholder="Playlist Name" type="text" onChange={(e) => setPlaylistName(e.target.value)} />
            <RoundButton className="add-btn" btnText={isCreating ? 'Creating...' : 'Create Playlist'} handleClick={handleCreatePlaylist} />
            {isCreating && <LoaderSpinner />}
          </div>
        </Popup>
      }
    </div>
  );
}

export default EditPlaylistMain;

