import { FormControl, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import './dropdown.scss';
import DropDownIconSvg from '../Svgs/DropdownIcon.svg';
import { isEmpty, map } from 'lodash';

const Dropdown = (props) => {
  const { value, setValue, options, style } = props;

  const handleChange = (event) => {
    const valueToSet = event.target.value;
    const option = options.find((option) => option.value === valueToSet);
    setValue(option);
  };

  const valueForDropdown = !isEmpty(value?.value) ? value.value : value;

  return (
    <FormControl
      variant='standard'
      style={style}
      required className="foreplay-dropdown">
      <Select
        value={valueForDropdown}
        onChange={handleChange}
        IconComponent={DropDownIconSvg}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          getContentAnchorEl: null,
        }}
      >
        {map(options, (option, index) => {
          return (
            <MenuItem style={{ minHeight: 50 }} value={option.value} key={index}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default Dropdown;

